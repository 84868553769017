/* Values
============================================ */
$sidebarWidth: 250px;

/* Fonts
============================================ */


/* Bootstrap
============================================ */

$size-sm: 540px;
$size-md: 720px;
$size-lg: 960px;
$size-xl: 1140px;

$container-max-widths: (
  sm: $size-sm,
  md: $size-md,
  lg: $size-lg,
  xl: $size-xl
);

$color-white:           #FFFFFF;
$color-cultured:        #FAFAFB;
$color-ghost-white:     #F5F6FA;
$color-ghost-white-2:   #E9E9F0;
$color-black:           #000000;
$color-jet:             #292A2B;
$color-onyx:            #3c4043;
$color-rebecca-purple:  #6E3DA4;
$color-purple-plum:     #8D5BC2;
$color-blue-bell:       #A6A4BE;
$color-fuzzy-wuzzy:     #D25E62;
$color-mantis:          #5cb85c;
$color-levi-blue:       #0092C8;
$color-levi-gray:       #4B4D50;
$color-levi-bg:         #f9f9fb;

$theme-colors: (
  "primary":    $color-levi-blue,
  "secondary":  $color-levi-gray,
  "success":    $color-mantis,
//  "info":     #9B9B9B,
//  "warning":  #f0ad4e,
  "danger":     $color-fuzzy-wuzzy,
//  "light":    #f4f4f4,
  "dark":       $color-jet,
);

$border-radius:               1rem !default;
$border-radius-lg:            1.25rem !default;
$border-radius-sm:            .5rem !default;

$btn-border-radius:           $border-radius-lg !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius !default;

$input-border-radius:         $border-radius-sm !default;
$input-border-radius-lg:      $border-radius !default;

$custom-select-border-radius: $border-radius-sm !default;

$card-spacer-y:               1rem !default;
$card-header-spacer-x:        1.75rem !default;
$card-border-width:           2px !default;
$card-border-color:           $color-ghost-white !default;

$enable-caret:                false !default;

$modal-inner-padding:         1.5rem !default;
$modal-header-padding-x:      1.5rem !default;

$popover-border-radius:       $border-radius-sm !default;
$popover-max-width:           450px !default;
$popover-arrow-width:         .5rem !default;
$popover-arrow-height:        .25rem !default;

$list-group-border-radius:    0 !default;
$list-group-border-width:     0 !default;
$list-group-bg:               transparent !default;

@import "~bootstrap/scss/bootstrap";

/* Video React
============================================ */

@import "~video-react/styles/scss/video-react";

/* Customize
============================================ */

body {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-levi-bg;
  color: $color-jet;
}

p, a {
  white-space: pre-line;
}

a {
  color: $color-jet;
}

.btn.a {
  color: $color-jet;
  padding: 0;

  &:hover {
    color: $color-levi-blue;
    transition: none;
  }
  &:focus {
    box-shadow: none;
  }
}

h1, h2, h3 {
  margin-bottom: 1.5rem;
  font-family: sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: $color-levi-blue;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.underline {
  text-decoration: underline;
}

.alert {
  padding: 1rem;
}

.alert-heading {
  font-size: 1rem;
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 0;
}

.bg-white {
  background-color: $color-white;
}

.toast-header {
  padding: 0.25rem 0.5rem;
  color: $color-mantis;
}

.toast {
  .toast-header {
    padding: 0.25rem 0.5rem;
  }
  &.success {
    .toast-header {
      color: $color-mantis;
    }
  }
  &.error {
    .toast-header {
      color: $color-fuzzy-wuzzy;
    }
  }
}

.fixed-toast {
  position: fixed;
  z-index: 100;
  right: 20px;
  top: 100px;
}

.icon {
  margin-bottom: 3px;
}

.br-1 {
  border-right: 1px solid $color-ghost-white;
}

.br-2 {
  border-right: 2px solid $color-ghost-white;
}

.bl-1 {
  border-left: 1px solid $color-ghost-white;
}

.bl-2 {
  border-left: 2px solid $color-ghost-white;
}

.card-header {
  background-color: $color-white;
  padding: $card-spacer-y $card-header-spacer-x;
}

.breadcrumb {
  background-color: $color-white;
  border: 1px solid rgba($color-black, 0.125);
  border-radius: 0.25rem;
}

.dropdown-btn-block {
  button {
    display: block;
    width: 100%;
  }

  .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 10%;
  }

  .dropdown-menu {
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pre-wrap {
  white-space: pre-wrap;
}

.invalid-tooltip {
  font-size: 0.75rem;
  right: 4px;
  left: auto;
}

.card {
  box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);
  border-width: 0;
}

.list-group-item {
  padding: 0;
  border-bottom: 1px solid $color-ghost-white;
  border-top: 1px solid $color-ghost-white;
}

/* Custom components
============================================ */

@mixin limit-text($rows) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $rows;
}

.limit-text-1 {
  @include limit-text(1);
}
.limit-text-2 {
  @include limit-text(2);
}
.limit-text-3 {
  @include limit-text(3);
}
.limit-text-4 {
  @include limit-text(4);
}
.limit-text-5 {
  @include limit-text(5);
}

@keyframes spin {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}

.slow-spin {
  animation: spin 1s linear infinite;
}

.btn-default-sm {
  min-width: 100px;
}

.btn-default {
  min-width: 120px;
}

.btn-default-lg {
  min-width: 200px;
}

@media only screen and (max-width: $size-sm) {
  .btn-block-xs-only {
    display: block;
    width: 100%;
  }
}

/* Auth
============================================ */

.auth {
  min-height: 100vh;

  .logo {
    width: 7.5rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: $size-sm) {
      margin-top: 2rem;
    }
  }

  .background-image  {
    overflow: hidden;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    height: auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      min-width: 100vh;
      object-fit: cover;
      position: absolute;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -ms-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;

      &.hidden {
        opacity: 0;
      }

      &.visible {
        opacity: 1;
      }
    }
  }

  .content {
    max-width: 800px;
    margin: auto;
  }

  .footer-links {
    a {
      font-size: 80%;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

/* Sidebar navigation
============================================ */

.sidebar-navigation {
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: $sidebarWidth;
  height: 100vh;
  transition: all .6s ease;

  &.show {
    transform: translate(0, 0);
  }

  &.hide {
    transform: translate(-250px, 0);
  }

  .dropdown-menu {
    margin-left: .25rem !important;
    border-width: 0;
    box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);
  }

  .nav-container {
    flex-wrap: nowrap;
    height: 100%;
    background-color: $color-white;
    box-shadow: 0px 1px 2px rgba($color-black, 0.1);
    -webkit-box-shadow: 0px 1px 2px rgba($color-black, 0.1);
    -moz-box-shadow: 0px 1px 2px rgba($color-black, 0.1);

    .logo {
      width: 5rem;
      margin: 2rem auto 0.5rem auto;
    }

    .nav-dest-selection {
      margin: 1.5rem 1rem;

      a {
        text-decoration: none;
      }

      .dest-container {
        padding: 1rem;
        background-color: $color-ghost-white;
        border-radius: 1rem;
        display: flex;
        flex-direction: row;
      }

      .title {
        margin: 0;
        font-weight: 600;
      }

      .subtitle {
        opacity: 0.5;
      }

      .left {
        flex: 1;
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
      }
    }
  
    .nav-spacer {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }

    .nav-title {
      padding: 0 1.75rem;
      font-weight: 600;
      color: lighten($color-jet, 50%);
    }

    .nav-link {
      font-weight: 400;
      padding: 1rem 1.75rem;
      margin-top: 3px;
      margin-bottom: 3px;
      border-right: solid 3px $color-white;

      &:hover {
        background-color: $color-ghost-white;
        border-right-color: $color-ghost-white;
      }

      &.active {
        border-right-color: $color-levi-blue;
        font-weight: 600;
        svg {
          color: $color-levi-blue;
        }
      }

      svg {
        color: $color-blue-bell;
        font-size: 1.25rem;
        margin-bottom: 3px;
        margin-right: 10px;
      }
    }
  }
}

.sidebar-toggle {
  position: fixed;
  z-index: 1032;
  top: 1rem;
  left: 0;
  transition: all .6s ease;

  &.open {
    transform: translate(200px, 0);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.closed {
    transform: translate(0, 0);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus {
    box-shadow: none;
  }
  
  svg {
    font-size: 1.5rem;
    margin-bottom: 2px;
  }
}

// Large screen
@media (min-width: $size-xl) {
  .has-sidebar {
    padding-left: $sidebarWidth + 20px;
  }
  .sidebar-toggle {
    display: none;
  }
  .sidebar-navigation {
    transform: none !important;
  }
}

/* Header navigation
============================================ */

.header-navigation {
  position: fixed;
  left: auto;
  top: 1rem;
  right: 2rem;
  z-index: 1030;
  margin: 0;
  padding: 0;
  border-radius: $border-radius-lg;
  box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);

  svg {
    font-size: 1.5rem
  }

  .dropdown-menu {
    border-width: 0;
    box-shadow: 0px 1px 5px 0px rgba($color-jet, 0.1);
    margin-top: .5rem !important;
  }
}

.has-header {
  margin-top: 3.5rem;
}

@media only screen and (max-width: $size-xl) {
  .has-header {
    margin-top: 5rem;
  }
}

/* Powered by
NOTE: used only on Visit Levi -application
============================================ */

.powered-by {
  padding: 1rem;
  color: $color-jet;
  font-size: 0.75em;
  
  a {
    color: $color-levi-blue;
  }
}

/* Language selector
============================================ */

.language-navigation {  
  .nav-link {
    opacity: 0.5;
    padding-top: 0;    
    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid $color-ghost-white-2;
  }
  .nav-link.active {
    opacity: 1;
    border-bottom: 2px solid $color-levi-blue;
  }
}

.language-picker {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 100; 
}

.language-picker-flag {
  margin-right: 5px;
  margin-bottom: 3px;
}

/* Form - Progress indicator
============================================ */

.progress-btn {
  float: left;
  text-align: center;

  .progress-btn-circle {
    width: 40px;
    height: 40px;
    border: 1px solid $color-levi-blue;
    border-radius: 40px;
    margin: auto;
    line-height: 39px;
  }
  
  .progress-btn-text {
    font-size: 0.75rem;
    margin-top: 5px;
  }

  &.active {
    .progress-btn-circle {
      background-color: $color-levi-blue;
      color: $color-white;
    }
  }
}

.progress-separator {
  float: left;
  width: 100px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba($color-levi-blue, 0.3);
  &.active {
    border-top: 1px solid $color-levi-blue;
  }
}

/* Guides
============================================ */

.card.guide {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: $color-ghost-white;
  box-shadow: none;
  cursor: pointer;
}

.card.selected {
  font-weight: 600;
  svg {
    color: $color-levi-blue;
  }
}

.card.locked {
  opacity: 0.5;
}

.loading-spinner {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($color-white, 0.25); 
  z-index: 100;
  margin: 0 -15px;

  .loading-spinner-icon {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    height: 40px;
    width: 40px;
  }
}

.list-group-item.selected {
  .list.image-container,
  .list.video-container,
  .list.text-container {
    background-color: $color-ghost-white !important;
  }

  .list {
    img, &.video-container {
      opacity: 0.5;
    }
  }
}

.image-container {
  background-color: $color-black;

  &.list {
    height: 95px;
    overflow-y: hidden;

    img {
      object-fit: cover;
      height: 95px;
      width: 100%;
    }
  }

  &.preview {
    margin-bottom: 0.25rem;

    img {
      width: 100%;
    }
  }

  &.fullsize {
    max-height: 400px;
    overflow-y: hidden;
    margin-bottom: 1rem;

    img {
      object-fit: contain;
      object-position: center;
      max-height: 400px;
      width: 100%;
    }
  }
}

.text-container {
  &.list {
    height: 95px;
    overflow-y: hidden;
    background-color: $color-white;
  }

  &.list, &.preview {
    padding: 0.75rem 1.25rem;
  }
}

.video-container {
  background-color: $color-black;
  overflow-y: hidden;

  &.list {
    height: 95px;

    .video-react-big-play-button {
      display: none;
    }
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.preview {
    max-height: 400px;
    margin-bottom: 0.25rem;
  }

  &.fullsize {
    max-height: 400px;
    margin-bottom: 1rem;
  }
}

.video-player {
  width: auto !important;
}

.guide-list-header {
  border-bottom: 1px solid $color-ghost-white;
}

.font-14 {
  font-size: 14px !important;
}

.round-shadow-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: $color-white;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  box-shadow: 2px 3px 6px 0px rgba($color-jet, 0.5);
  z-index: 10;
}

/* Destination
============================================ */

.destination {
  margin-bottom: 1rem;

  img {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

.destination-title {
  font-size: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-ghost-white
}

.destination-text,
.destination-description {
  font-size: 14px;
}

.destination-description {
  opacity: 0.5;
}

.destination-access-code {
  border-radius: $border-radius;
  background-color: $color-ghost-white;
  padding: 5px 0;
}

/* Icon Picker
============================================ */

.icon-picker-modal {
  height: 600px;
}

.icon-picker,
.icon-picker-button {
  &:focus, &.focus {
    box-shadow: none !important;
  }
}

.icon-picker-button {
  width: 25%;
  height: auto;
  color: $color-jet;
  border-radius: 0;
  border-width: 0;

  &.selected {
    background-color: $color-levi-blue;
    color: $color-white;
    border-width: 0;
  }

  .icon-picker-icon {
    width: 100%;
    height: 100%;
    padding: 20px;
  }
}

/* Save Footer button
============================================ */

.fixed-footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  padding: .75rem 2rem;
  z-index: 10;
  box-shadow: 0px -1px 5px 0px rgba($color-jet, 0.1);
}

@media (min-width: $size-xl) {
  .fixed-footer-bar {
    margin-left: $sidebarWidth;
  }
}
